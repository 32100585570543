import MemberCard from "../Components/TeamDetailsPage/MemberCard";
import GoBack from "../Components/General/GoBack";
import { useEffect } from "react";

function TeamDetailsPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<div className="column centerAlign teamDetailsPage" style={{ marginTop: "7vh" }}>
        <GoBack link="/" />
        <h1>O nás</h1>
        <div className="column centerAlign">
            <MemberCard name={"Jiří Kryštof Jedlička"} desc={"Organizace, úlohy, komunikace"} email={"jedlicka@smoulasoutez.cz"} img={require("../Assets/images/logos/faviconSmaller.png")} />
            <MemberCard name={"David Bojko"} desc={"Organizace, úlohy, IT"} email={"bojko@smoulasoutez.cz"} img={require("../Assets/images/pfps/db.png")} />
            <MemberCard name={"Vojtěch Štefek"} desc={"Organizace, komunikace"} email={"stefek@smoulasoutez.cz"} img={require("../Assets/images/logos/faviconSmaller.png")} />
            <MemberCard name={"Karan Julius Sonakiya"} desc={"Úlohy"} email={false} img={require("../Assets/images/logos/faviconSmaller.png")} />
            <MemberCard name={"Iryna Serykava"} desc={"Ilustrace"} email={false} img={require("../Assets/images/logos/faviconSmaller.png")} />
            <MemberCard name={"Anita Bíróczi a Lucie Musilová"} desc={"Testování úloh"} email={false} img={require("../Assets/images/pfps/abAlm.jpg")} />
            <br />
        </div>
    </div>);
}

export default TeamDetailsPage;
