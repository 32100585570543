import { useEffect, useState } from "react";

import SponsorCard from "./SponsorCard";

import O2Logo from "../../Assets/images/logos/o2logo.png"
import KCLogo from "../../Assets/images/logos/KClogo.png"

function Sponsors() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="column centerAlign sponsorContainer">
            <h1 style={{ color: "#000", paddingBottom: "10vh" }}>Partneři soutěže</h1>
            {/*<h1 style={{ color: "#000", paddingBottom: "10vh" }}>Partner soutěže</h1>*/}
            <div className={`${isMobile ? "column" : "row"} centerAlign`} style={{ gap: isMobile ? "30px" : "0px" }}>
                <SponsorCard imgUrl={O2Logo} url="https://www.o2chytraskola.cz" title="Nadace O2" showTitle={false} />
                <SponsorCard imgUrl={KCLogo} url="https://kultura.klasterec.cz/kulturni-centrum/" title="Kulturní centrum Klášterec nad Ohří" showTitle={false} />
            </div>
        </div>
    );
}

export default Sponsors;