function MemberCard({ name, desc, email, img }) {
    const isLabRat = desc === "Testování úloh";

    let hasEmail = email !== "";

    return (
        <div className={`memberCard row ${isLabRat ? "labRatCard" : ""}`}>
            <img src={img} alt={name + " pfp"} />
            <div className="column" style={{ alignItems: "start" }}>
                <h2 style={{ fontSize: "max(2.3vw,2.4vh)" }}>{name}</h2>
                <h3 className="desc" style={{ fontSize: "max(1.4vw,1.5vh)" }}>
                    {desc}
                </h3>
                {hasEmail ? <h4 className="email" style={{ fontSize: "max(1.2vw,1.3vh)" }}><a href={`mailto:${email}`}>{email}</a></h4> : ""}
            </div>
        </div>
    );
}

export default MemberCard;
