import '../Styles/main.css';

import GoBack from '../Components/General/GoBack';

function PrivacyPolicyPage() {
    return (
        <>
            <GoBack link="/" />
            <div className='container column centerAlign'>
                <div className='privacyPolicy'>
                    <h1>Zásady ochrany osobních údajů</h1>
                    <p>Cookies jsou malé textové soubory, které mohou být použity webovými stránkami, aby zefektivnily uživatelské možnosti.</p>
                    <p>Zákon říká, že můžeme ukládat soubory cookie na vašem zařízení, pokud jsou nezbytné pro provoz těchto stránek. U všech ostatních typů souborů cookie potřebujeme váš souhlas.</p>
                    <br />
                    <p>Tato stránka používá různé typy cookies. Některé cookies jsou zde umístěny z důvodu využití služeb třetích stran.</p>
                    <br />
                    <p>Potřebné soubory cookie pomáhají vytvářet použitelné webové stránky tak, aby umožnily základní funkce, jako je navigace stránky a přístup k chráněným oblastem webových stránek. Webové stránky nemohou řádně fungovat bez těchto souborů cookies.</p>
                    <br />
                    <p>Potřebné cookies:</p>
                    <p>Stav souhlasu uživatele cookie pro aktuální doménu</p>
                    <p>Uchovává se stav relace uživatele ve všech požadavcích na stránku.</p>
                    <br />
                    <p>Ostatní cookies:</p>
                    <p>Z důvodu hodnocení využití stránek používáme službu Google Analytics.</p>
                    <p>Z důvodu poskytování online podpory používáme službu Liveagent.</p>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicyPage;