import { useEffect, useState } from "react";
import AnimBg from "../Animated/AnimBg";
import Icon from "../General/Icon";

interface Props {
    delayMs: number
}

function Header({ delayMs }: Props) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        checkIfMobile();
        window.addEventListener("resize", checkIfMobile);

        return () => window.removeEventListener("resize", checkIfMobile);
    }, []);

    function options(
        backgroundColor: string,
        textColor: string,
        accentColor: string,
        oText: string,
        uText: string,
        aText: string
    ) {

        const titleSeriesVars = { 
            "--duration": "0.6", 
            "--in-duration": "0.5s", 
            "--out-duration": "0.5s", 
            "--overflow": "0.8s", 
            "--delay": "0.4s",
            "--ease-in": "cubic-bezier(0.4, 0, 0, 1)",
            "--ease-out": "cubic-bezier(0, 0, 0.2, 1)",
            "margin": "0vw 0.7vw", 
            width: "50vw", 
            fontSize: "max(5vw, 3.5vh)", 
            color: textColor, 
            fontFamily: "Waffle" 
        } as React.CSSProperties
        return (
            <div className="column centerAlign" style={{ backgroundColor: backgroundColor, paddingBottom: "0" }}>
                <div className="titleContainer" style={{ marginTop: "7%" }}>

                    <div className="title logo column elementSeries fadeRightToLeft" style={titleSeriesVars}>
                        <p className="row stay"><span style={{ color: accentColor }}>Š</span>ifrovací,</p>
                        <p className="row stay"><span style={{ color: accentColor }}>M</span>atematická,</p>
                        <p className="row stay">{oText},</p>
                        <p className="row stay">{uText},</p>
                        <p className="row stay"><span style={{ color: accentColor }}>L</span>ogická,</p>
                        <p className="row stay">{aText}.</p>
                    </div>

                    <div className="arrow" style={{ marginTop: "4vh" }}>
                        <Icon iconName="arrow-down-sharp" size="3vw" color={textColor} />
                    </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave"><path fill="#4695d6" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,186.7C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                <div style={{ color: "#4695D6", backgroundColor: "#4695D6", height: "5px", width: "50vw", transform: "scale(2)", zIndex: "999999999999", overflow: "hidden" }}>.</div>
            </div>
        );
    }

    function optionsPhone(
        backgroundColor: string,
        textColor: string,
        accentColor: string,
        oText: string,
        uText: string,
        aText: string
    ) {
        return (
            <div className="column centerAlign" style={{ backgroundColor: "#27456b", paddingBottom: "0", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div className="titleContainer" style={{ marginTop: "7%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="title logo column" style={{ width: "60vw", fontSize: "4vh", color: "#ffffff", fontFamily: "Waffle", textAlign: "center" }}>
                        <p className="row"><span style={{ color: "#4695D6" }}>Š</span>ifrovací,</p>
                        <p className="row"><span style={{ color: "#4695D6" }}>M</span>atematická,</p>
                        <p className="row">originální,</p>
                        <p className="row">univerzální,</p>
                        <p className="row"><span style={{ color: "#4695D6" }}>L</span>ogická,</p>
                        <p className="row">akustická.</p>
                    </div>
                    <div className="arrow" style={{ marginTop: "4vh" }}>
                        <Icon iconName="arrow-down-sharp" size="10vw" color="#ffffff" />
                    </div>
                </div>
                <div style={{ position: "absolute", bottom: "0", width: "100vw" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave"><path fill="#4695d6" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,186.7C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                </div>
                <div style={{ color: "#4695D6", backgroundColor: "#4695D6", height: "5px", width: "50vw", transform: "scale(2)", zIndex: "999999999999", overflow: "hidden", position: "absolute", bottom: "0" }}>.</div>
            </div>
        );
    }

    const getOptions = () => {
        return isMobile
            ? optionsPhone("#27456b", "#ffffff", "#4695D6", "originální", "univerzální", "akustická")
            : options("#27456b", "#ffffff", "#4695D6", "originální", "univerzální", "akustická");
    };

    return <div>{getOptions()}</div>;
}

export default Header;
