import AnimWave from "../../Components/Animated/AnimWave";
import GoBack from "../../Components/General/GoBack";

function ThankYouPage() {
    return ( 
        <div className="container column centerAlign">
            <GoBack link="/"/>

            <h1>Děkujeme za registraci!</h1>
            <h2>Brzy obdržíte email s dalšími kroky</h2>

            <AnimWave/>
        </div>
     );
}

export default ThankYouPage;