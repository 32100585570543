function Archive() {
    return (
        <div className="registration centerAlign column">

            <div style={{ width: "65vw", marginTop: "3vh" }}>
                <h1>Zkontrolujte <i>archiv!</i></h1>
                <br />
                <div className="row centerAlign">
                    <a href="https://archiv.smoulasoutez.cz">
                        <div className="column card centerAlign">
                            <h3 style={{ fontSize: "max(2vw,2.3vh)" }}>Úlohy z minulého ročníku zde</h3>
                        </div>
                    </a>
                </div>
            </div>

            <hr style={{ width: "80vw", marginTop: "5vh" }} />

        </div>
    );
}

export default Archive;