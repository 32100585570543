/* eslint-disable no-useless-escape*/
import Joi from "joi";

/*
  Requests
*/

const defaultUrl = "http://localhost:8080/separateSite"

const sendReq = (type: "GET" | "POST", url: string, endpoint: string, body: Object, errMsgSetter?: Function) => {
    return fetch(url + endpoint, {
      method: type,
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(async (res) => {
        let temp = await res.json();
        temp.status = res.status;
        return temp;
      })
      .catch((err) => {
        console.log(err);
        if (errMsgSetter) {errMsgSetter("general-err")}
      });
  }
  
  export const sendGetReq = async (endpoint: string, body: Object, errMsgSetter?: Function, url: string = defaultUrl) => {
    return await sendReq("GET", url, endpoint, body, errMsgSetter);
  };
  
  export const sendPostReq = async (endpoint: string, body: Object, errMsgSetter?: Function, url: string = defaultUrl) => {
    return await sendReq("POST", url, endpoint, body, errMsgSetter);
  };


/*
  Miscelaneous
*/
export const onChange = (data: Object, setData: Function, e: any) => {
  let temp = data;
  let id: keyof typeof temp = e.target.id;
  temp[id] = e.target.value;
  setData(temp);
};

export const compressObjects = (data: Object[], key: string) => {
  return data.map((o : {[key: string]: any}) => {
    return o[key]
  })
}

export const createArr = (len: number, defaultVal: any = null) => {
  return Array.apply(defaultVal, Array(len)).map(function () {return defaultVal})
}


/*
  General types
*/
export type StrIndexedObj = {[key: string]: any}

export interface Problem {
  id: number,

  title: string
  type: "s" | "m" | "l"
  content: string,
  img?: string,
  note?: string,

  xPos: number,
  yPos: number,
  children: number[],

  state: number // Whether or not the problem is unlocked
  /* 1: locked; 2: active; 3: solved */
}

/* Registration */
export interface MemberFull extends StrIndexedObj { //yupp
  [key: string]: any;

  teamId?: number,

  name: string,
  birthDate: string,
  schoolYear: number,

  parentName?: string,
  parentEmail?: string
  gdpr: boolean
}

export interface RegistrationData {
    id?: string,
    teamName: string,
    motto: string,

    school: string,
    schoolCustom: string,

    email: string,
    teacherEmail?: string,
    newsletter: boolean,
  
    password: string,
    repeatPassword: string,
    
    members: MemberFull[]
  }

export interface MemberConfirmation {
    name: string,
    parentName: string,
    gdpr: boolean
  }

export interface PassResetData {
  token: string,
  password: string,
  repeatPassword: string
}

/*
  Joi validation (jesus)
*/

// Useful functions
export const isUserOldEnough = (userDate: Date, age: number) => {
  const birthday = new Date(userDate);
  const now = new Date();
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const utc2 = Date.UTC(
    birthday.getFullYear(),
    birthday.getMonth(),
    birthday.getDate()
  );

  const difference = Math.floor((utc1 - utc2) / MS_PER_DAY / 365.25);

  return difference >= age;
};


const presets = {

  token: Joi.string()
    .pattern(new RegExp(`^[^<>"';*\/]*$`))
    .required()
    .messages({
      "string.empty": `Prosím zadej token`,
      "string.base": "Prosím zadej validní token",
      "any.required": "Prosím zadej token"
    }),

  // Authentication attributes
  email: Joi.string()
    .pattern(new RegExp(`^[^<>"';*\/]*$`), "specialChars")
    .email({ tlds: { allow: false } })
    .max(100)
    .required()
    .messages({
      "string.base": "Prosím zadej validní email.",
      "string.email": "Prosím zadej validní email.",
      "string.empty": "Prosím zadej email.",
      "any.required": "Prosím zadej email.",
      "string.pattern.name": "Email nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
    }),

  passwordLogin: Joi.string()
    .pattern(new RegExp(`^[^<>"';*\/]*$`), "specialChars")
    .required()
    .messages({
      "any.required": "Prosím zadej heslo.",
      "string.empty": "Prosím zadej heslo.",
      "string.pattern.name": "Heslo nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
    }),

  passwordRegistration: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.{8,50}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&-_]).*$"
      )
    )
    .pattern(new RegExp(`^[^<>"';*\/]*$`), "specialChars")
    .required()
    .max(255)
    .messages({
      "string.base": "Prosím zadej validní heslo.",
      "string.pattern.base":
        "Heslo musí mít 8 až 50 znaků a musí obsahovat alespoň jedno malé písmeno, jedno velké písmeno, jednu číslici a jeden speciální znak.",
      "string.pattern.name":
        "Heslo nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
      "any.required": "Prosím zadej validní heslo.",
      "string.empty": "Prosím zadej heslo.",
    }),

  repeatPassword: Joi.string()
    .valid(Joi.ref('password')) // Ensures passwords match
    .required()
    .max(255)
    .messages({
      "any.only": "Ujisti se, že zadaná hesla se shodují.",
      "string.pattern.base":
        "Heslo nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
      "any.required": "Prosím zopakuj svoje heslo.",
      "string.empty": "Prosím zopakuj svoje heslo.",
    }),


  // Registration

  teamName: Joi.string()
    .pattern(new RegExp(`^[^<>"';*\/]*$`))
    .min(3)
    .max(30)
    .required()
    .messages({
      "string.empty": `Prosím zadej název týmu`,
      "string.base": "Prosím zadej validní název týmu.",
      "string.min": "Název týmu může být minimálně 3 znaky dlouhý.",
      "string.pattern.base": "Motto nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
      "string.max": "Název týmu může být maximálně 30 znaků dlouhý.",
    }),

  motto: Joi.string()
    .allow("")
    .pattern(new RegExp(`^[^<>"';*\/]*$`))
    .max(100)
    .messages({
      "string.base": "Prosím zadej validní motto.",
      "string.pattern.base": "Motto nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
      "string.max": "Motto může být maximálně 100 znaků dlouhé.",
    }),

    school: Joi.string()
    .allow("")
    .pattern(new RegExp(`^[^<>"';*\/]*$`))
    .max(50)
    .messages({
      "string.base": "Prosím zadej validní název školy.",
      "string.pattern.base": "Název školy nesmí obsahovat žádné speciální znaky.",
      "string.max": "Název školy muže být maximálně 50 znaků dlouhý.",
    }),

    schoolCustom: Joi.string()
    .allow("")
    .pattern(new RegExp(`^[^<>"';*\/]*$`))
    .max(50)
    .messages({
      "string.base": "Prosím zadej validní název školy.",
      "string.pattern.base": "Název školy nesmí obsahovat žádné speciální znaky.",
      "string.max": "Název školy muže být maximálně 50 znaků dlouhý.",
    }),



  teamMember: Joi.object({

    name: Joi.string()
      .pattern(new RegExp(`^[^<>"';*\/]*$`))
      .max(50)
      .required()
      .messages({
        "string.base": "Prosím zadej validní jméno",
        "string.pattern.base": "Jméno nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
        "string.max": "Jméno muže být maximálně 50 znaků dlouhé.",
        "string.empty": "Prosím zadej jméno nebo přezdívku člena týmu",
      }),

    /*birthDate: Joi.date()
        .format('YYYY-MM-DD')
        .utc()
        .messages({
            "date.format": "Prosím zadej validní datum narození ve formátu YYYY-MM-DD",
            "date.empty": "Prosím zadej datum" //TODO: Figure out date err msgs
        }),*/ //TODO: Fix the date format err????

    birthDate: Joi.string().required().messages({
      "string.base": "Prosím zadej validní datum",
      "string.empty": "Prosím zadej datum narození",
    }),

    schoolYear: Joi.number().min(6).max(9).required().allow(0).messages({
        "number.base": "Prosím zadej validní ročník.",
        "number.min": "Prosím zadej validní ročník.",
        "number.max": "Prosím zadej validní ročník.",
      }),

    parentName: Joi.string()
      //.pattern(new RegExp('^[^<>"\'--;/*]*$'))
      .pattern(new RegExp(`^[^<>"';*\/]*$`))
      .max(50)
      .when("birthDate", {
        is: Joi.string().custom((value: any, helper: any) => {
          if (!isUserOldEnough(value, 15)) {
            return helper.error("string.empty");
          }
          return value;
        }),
        then: Joi.allow(""),
        otherwise: Joi.invalid(""),
      })
      .messages({
        "string.base": "Prosím zadej validní jméno zákonného zástupce",
        "string.pattern.base": "Jméno nesmí zahrnovat žádný z těchto znaků: <, >, \", ', ;, --, /, nebo *.",
        "string.max": "Jméno muže být maximálně 50 znaků dlouhé.",
        "string.empty": "Prosím zadej jméno zákonného zástupce",
        "any.invalid": "Prosím zadej jméno zákonného zástupce", //TODO: Figure out if all of these err msgs are necessary
      }),

    parentEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .empty()
      .max(100)
      .required()
      .when("birthDate", {
        is: Joi.string().custom((value: any, helper: any) => {
          if (!isUserOldEnough(value, 15)) {
            return helper.error("string.empty");
          }
          return value;
        }),
        then: Joi.valid(""),
        otherwise: Joi.invalid(""),
      })
      .messages({
        "string.base": "Prosím zadej validní email zákonného zástupce.",
        "string.email": "Prosím zadej validní email zákonného zástupce.",
        "string.empty": "Prosím zadej email zákonného zástupce.",
        "any.required": "Prosím zadej email zákonného zástupce.",
        "any.invalid": "Prosím zadej email zákonného zástupce", //TODO: Figure out if all of these err msgs are necessary
      }),

    gdpr: Joi.boolean()
      .when("birthDate", {
        is: Joi.string().custom((value: any, helper: any) => {
          if (!isUserOldEnough(value, 15)) {
            return helper.error("string.empty");
          }
          return value;
        }),
        then: Joi.valid(true),
        otherwise: Joi.valid(false),
      })
      .messages({
        "any.only": "Pro příhlášení musí každý člen nad 15 let souhlasit se zpracováním osobních údajů",
      }),
  })

}


// Joi schemas
export const registrationSchema = Joi.object({
  teamName: presets.teamName,

  motto: presets.motto,
  pfp: Joi.string().allow(""), //TODO: Figure out how to handle pfps

  school: presets.school,
  schoolCustom: presets.schoolCustom,

  email: presets.email,
  teacherEmail: presets.email.allow(""),
  newsletter: Joi.boolean(),

  password: presets.passwordRegistration,
  repeatPassword: presets.repeatPassword,

  members: Joi.array().items(presets.teamMember).min(1).max(3)
}).with("password", "repeatPassword")