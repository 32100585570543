import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MemberConfirmation } from "../../Scripts/misc";
import GoBack from "../../Components/General/GoBack";
import { confirmMember, getConfirmationData } from "../../Scripts/dataHandler";

function RegistrationConfirmationPage() {

    let [searchParams] = useSearchParams()
    let [memberData, setMemberData] = useState<MemberConfirmation | undefined>(undefined)

    useEffect(() => {
        getConfirmationData(searchParams.get("token") || "",setMemberData)
    },[searchParams])

    const confirm = () => {
        confirmMember(searchParams.get("token") || "",setMemberData)
    }

    return ( 
        <div className="container column centerAlign">
            <GoBack link="/"/>

            {memberData ? <>
            {memberData.gdpr ? <>
                <h2>Registrace potvrzena!</h2>
                <h3 style={{maxWidth: "65vw"}}>Přejeme mnoho štestí v soutěži</h3>
            </> : <>
                <h2 style={{maxWidth: "65vw"}}>Stisknutím tlačítka níže souhlasím já, <br/><strong><i>{memberData.parentName}</i></strong>,<br/>se zpracováním osobních údajů mého dítěte <i>{memberData.name}</i> pro účely soutěže ŠMouLa podle <a href="https://static.smoulasoutez.cz/souhlas_se_zpracovanim_osobnich_udaju_smoula.pdf" target="_blank" rel="noreferrer">zásad zpracování osobních údajů</a></h2>
                <button style={{fontSize: "max(3vw,4vh)"}} onClick={confirm}>Potvrdit</button>
            </>}</> : <>
                <h2>Omlouváme se, ale toto není validní potvrzovací token. Zkuste prosím stránku za pár sekund obnovit. Pokud problémy přetrvávají, kontaktujte nás na info@smoulasoutez.cz</h2>
            </>}
        </div>
     );
}

export default RegistrationConfirmationPage;