import { useEffect, useState } from "react";
import AnimWave from "../../Components/Animated/AnimWave";
import { checkPassResetToken, passResetReq } from "../../Scripts/dataHandler";
import { useSearchParams } from "react-router-dom";
import { useFormHandler } from "../../Scripts/hooks/useFormHandler";
import GoBack from "../../Components/General/GoBack";

function PassResetPage() {

    let [searchParams] = useSearchParams()
    let [exists, setExists] = useState<boolean | undefined>(undefined)

    let {formData, setFormData, formChange} = useFormHandler({
        token: "",
        password: "",
        repeatPassword: ""
    })
    
    useEffect(() => {
        checkPassResetToken(searchParams.get("token") || "", setExists)
        setFormData((prev: any) => {
            let updated = {...prev}
            updated.token = searchParams.get("token") 
            return updated
        }) // hahaaaa
    },[searchParams])


    let [err, setErr] = useState("")

    const submit = () => {
        passResetReq(formData, setErr)
    }

    return ( 
        <div className="container column centerAlign">

            <GoBack link="/" />

            <h1>Změna hesla</h1>

            {exists === undefined ? <>loading</> : <>{
                exists ? <div className="column centerAlign">

                    <h2>Heslo:</h2>
                    <input type="password" id="password" onChange={formChange}/>

                    <h2>Zopakuj heslo:</h2>
                    <input type="password" id="repeatPassword" onChange={formChange}/>

                    <div className="submit" style={{margin: "2vh 0vw"}}>
                        <button onClick={submit}>Odeslat</button>
                        <p>{err}</p>
                    </div>

                </div> : <div>
                    Tento token neexistuje, zkuste prosím o změnu hesla zažádat znovu. Pokud problem přetrvává, kontaktujte nás na info@smoulasoutez.cz
                </div>
            }</>}

            <AnimWave/>

        </div>
     );
}

export default PassResetPage;