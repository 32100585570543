import { useSpecialClassHandler } from "../../Scripts/hooks/useSpecialClassHandler";
import { useFormHandler } from "../../Scripts/hooks/useFormHandler";
import { useEffect, useState } from "react";

import { getSchools, registerReq } from "../../Scripts/dataHandler";
import { isUserOldEnough, RegistrationData, registrationSchema } from "../../Scripts/misc";

import Slideshow, { useSlideshowController } from "../../Components/General/Slideshow/Slideshow";
import AnimWave from "../../Components/Animated/AnimWave";

import "../../Styles/registrationPage.css"
import GoBack from "../../Components/General/GoBack";

export interface MemberFull {
    name: string,
    birthDate: string,
    schoolYear: number,

    parentName?: string,
    parentEmail?: string
    gdpr: boolean
}

function RegistrationPage() {

    useSpecialClassHandler()

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    let [schools, setSchools] = useState(["Jiné"])
    useEffect(() => {
        getSchools(setSchools)
    }, [])

    let [err, setErr] = useState("Vyplň prosím registrační formulář")
    let [validated, setValidated] = useState(false)

    let { formData, setFormData, formChange } = useFormHandler<RegistrationData>({
        teamName: "",
        motto: "",

        school: "",
        schoolCustom: "",

        email: "",
        teacherEmail: "",
        newsletter: false,

        password: "",
        repeatPassword: "",

        members: []
    }, undefined, true)

    const check = () => {
        if (formData.id) { setFormData(prev => { let updated = prev; delete updated.id; return updated }) } // i got no other way of handling this ¯\_(ツ)_/¯
        let result = registrationSchema.validate(formData)
        if (result.error) {
            setValidated(false)
            setErr(result.error.details[0].message)
        } else {
            setValidated(true)
            setErr("")
        }
    }

    const onChange = async (e: any) => {
        formChange(e);
        setTimeout(check, 100)
        return
    }

    const [pfp, setPfp] = useState(false)
    const updatePfpPreview = (e: any) => {
        const previewElem = document.getElementById("pfpPreview") as HTMLImageElement

        const file = e.target.files[0];
        if (!file) { return }

        if (file.size > 5 * 1024 * 1024) { // max file size of 5mb
            alert("Nahraný obrázek je příliš velký, maximální povolená velikost je 5MB")
            return
        }

        let url = URL.createObjectURL(file)
        setPfp(true)

        previewElem.src = url;
        previewElem.style.width = "20vw";
        previewElem.style.height = "20vw";
        previewElem.onload = () => {
            URL.revokeObjectURL(previewElem.src);
        }
    }

    const submit = () => {
        if (validated) {
            registerReq(formData, setErr)
        }
    }


    // MEMBERS
    let controller = useSlideshowController([<h2>1</h2>, <h2>2</h2>, <h2>3</h2>])

    const memberChange = (e: any, memberI: number) => { // it eewy but it work :)
        let tag = e.target.id;
        let val: any;
        if (e.target.type === "checkbox") {
            val = e.target.checked;
        } else {
            val = e.target.value;
        }

        setFormData(prev => {
            const updated = { ...prev };
            updated.members = [...prev.members];
            updated.members[memberI][tag] = val
            return updated;
        });

        setTimeout(check, 100)

        return;
    }

    const updateMembers = (memberAmt: number) => {
        if (formData.members.length === memberAmt) { return }
        setFormData(prev => {
            let updated = prev
            if (updated.members.length > memberAmt) {
                updated.members.pop()
            } else {
                updated.members.push({
                    name: "",
                    birthDate: "",
                    schoolYear: 0,

                    parentName: "",
                    parentEmail: "",

                    gdpr: false
                })
            }
            return updated
        })
        setTimeout(check, 100)
    }

    const mapMembers = (memberAmt: number) => {
        updateMembers(memberAmt)

        let counter = -1;
        return formData.members.slice(0, memberAmt).map(m => {
            counter++;
            let i = counter.valueOf();
            return <div className="memberInfo">
                <h2>Člen {i + 1}</h2>

                <h2>Jméno a příjmení žáka:*</h2>
                <input type="text" id="name" onChange={(e) => { memberChange(e, i) }} />

                <h2>Datum narození:*</h2>
                <input type="date" id="birthDate" onChange={(e) => { memberChange(e, i) }} />


                <h2>Třída</h2>
                <input type="number" min={6} max={9} id="schoolYear" style={{ width: "8vw" }} onChange={(e) => { memberChange(e, i) }} />
                <h4>Čísla 6-9 reprezentují třídy základní školy a odpovídající ročníky víceletých gymnázií. <br />Pokud do tohoto intervalu nespadáte, zadejte 0 - Váš tým se tím ale vzdává možnosti získat ceny a být vyhlášen.</h4>

                {!isUserOldEnough(new Date(formData.members[i].birthDate), 15) ?
                    <div>
                        <h2>Jméno zákonného zástupce</h2>
                        <input type="text" id="parentName" onChange={(e) => { memberChange(e, i) }} />

                        <h2>Email zákonného zástupce</h2>
                        <input type="text" id="parentEmail" onChange={(e) => { memberChange(e, i) }} />
                        <br />
                        <small>E-mail potřebujeme kvůli <a href="https://static.smoulasoutez.cz/souhlas_se_zpracovanim_osobnich_udaju_smoula.pdf" target="_blank" rel="noreferrer">souhlasu se zpracováním osobních údajů.</a></small>
                    </div>
                    :
                    <div className="row centerAlign">
                        <h3>Souhlasím se <a href="https://static.smoulasoutez.cz/souhlas_se_zpracovanim_osobnich_udaju_smoula_starsi15let.pdf" target="_blank" rel="noreferrer">zpracováním osobních údajů</a></h3>
                        <input type="checkbox" id="gdpr" style={{ marginRight: "0vw" }} onChange={(e) => { memberChange(e, i) }} />
                    </div>
                }

            </div>
        })
    }

    return (<>
        <div className="container column">

            <GoBack link="/" />


            <div className="regForm">

                <h1 className="title">Registrace</h1>

                <div className="basicInfo column">
                    <div className="column">
                        <h2>Název týmu*</h2>
                        <input type="text" id="teamName" onChange={onChange} />
                    </div>
                    <div className="column">
                        <h2>Motto</h2>
                        <input type="text" id="motto" onChange={onChange} />
                    </div>

                    <div className="column centerAlign">
                        <h2>Profilový obrázek</h2>
                        <label htmlFor="teamPfpSelector" className="teamPfpSelector">Nahrát obrázek (.png)</label>
                        <input type="file" id="teamPfpSelector" accept=".png" onChange={updatePfpPreview} />
                        <br />
                        <img id="pfpPreview" alt="náhled profilového obrázku" src="" style={pfp ? {} : { visibility: "hidden" }} />
                    </div>
                </div>

                <h2>Škola</h2>
                <select id="school" onChange={onChange}>
                    <option value=""></option>
                    {schools.map(s => <option value={s} key={s}>{s}</option>)}
                </select>
                {formData.school === "Jiné" && <input type="text" id="schoolCustom" onChange={onChange} />}
                <p>Pokud vaše škola není jednou z možností, vyberte prosím "Jiné" a název školy zadejte ručně</p>

                <div className="contactInfo column centerAlign">
                    <div className="column centerAlign">
                        <h2>Email*</h2>
                        <input type="email" id="email" onChange={onChange} />
                        <div className="row centerAlign">
                            <h3 style={{ paddingBottom: "2px" }}>Newsletter:</h3>
                            <input type="checkbox" id="newsletter" onChange={onChange} style={{ width: "30px", height: "30px" }} />
                        </div>
                    </div>
                    <div className="column centerAlign">
                        <h2>Email učitele</h2>
                        <input type="email" id="teacherEmail" onChange={onChange} />
                        <h4>(pokud jste učitel, zadejte email prosím i sem, vytvoří se vám tím účet na <a href="https://ucitel.smoulasoutez.cz/">učitelském portálu</a>)</h4>
                    </div>
                </div>

                <div className={`loginInfo ${isMobile ? "column" : "row"}`}>
                    <div className="column">
                        <h2>Heslo*</h2>
                        <input type="password" id="password" onChange={onChange} />
                    </div>
                    <div className="column">
                        <h2>Zopakuj heslo*</h2>
                        <input type="password" id="repeatPassword" onChange={onChange} />
                    </div>
                </div>

                <h4>(heslo musí mít 8-50 znaků, obsahovat malé a velké písmeno, číslici a speciální znak)</h4>

                <div className="memberInfo column centerAlign">
                    <h2>Členové</h2>
                    <div className="amtSelect row">
                        <button onClick={() => { controller.prevPage() }} style={controller.pageI < 1 ? { visibility: "hidden" } : {}}>-</button>
                        <Slideshow controller={controller} />
                        <button onClick={() => { controller.nextPage() }} style={controller.pageI > 1 ? { visibility: "hidden" } : {}}>+</button>
                    </div>

                    <div className={`${isMobile ? "column" : "row"}`}>
                        {mapMembers(controller.pageI + 1)}
                    </div>
                </div>

                <div className="submit">
                    <button className={validated ? "" : "disabled"} onClick={submit}>Odeslat</button>
                    <h3>{err}</h3>
                </div>

                <h3>Tip: pokud vám nejde zmáčknout tlačítko odeslat, nechte si formulář překontrolovat:</h3>
                <button onClick={check}>Překontrolovat</button>

                <br /><br /><br /><br />

            </div>

            <AnimWave />
        </div>
    </>
    );
}

export default RegistrationPage;

// idk if any of this is good code or not, but I am quite proud of it nontheless :3