import React from 'react';

interface Props {
    imgUrl: string;
    url: string;
    title: string;
    showTitle: boolean;
};

function SponsorCard({ imgUrl, url, title, showTitle }: Props) {
    return (
        <span style={{ paddingLeft: "10vw", paddingRight: "10vw" }} className="sponsorLogo" id={`${title}Logo`}>
            <a href={url} title={title} target="_blank" rel="noreferrer">
                <img src={imgUrl} alt={`${title} logo`} />
                {showTitle && <h2 style={{ color: "black", fontSize: "3vh" }}>{title}</h2>}
            </a>
        </span>
    );
};

export default SponsorCard;