import Countdown from "react-countdown";
import { Link } from "react-router-dom";

function Registration() {
    return ( 
        <div className="registration centerAlign column">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave"><path fill="#4695d6" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,186.7C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave secondary fadeInBottom"><path fill="#ffffff" fillOpacity="1" d="M0,192L80,186.7C160,181,320,171,480,186.7C640,203,800,245,960,245.3C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>

                <div style={{width: "65vw", marginTop: "35vh"}}>
                    <h1>Registrace <i>otevřeny!</i></h1>
                    <br/>
                    <h3>Přihlášky se uzavírají za</h3>

                    <div className="countdown">
                        <Countdown date={new Date("2025-05-04T23:59:59")} />
                    </div>

                    <h2>(4. 5. 2025, 23:59:59)</h2>

                    <Link to="/registrace">
                        <button><h2>Registrace</h2></button>
                    </Link>
                </div>

                <hr style={{width: "80vw", marginTop: "5vh"}}/>

        </div>
     );
}

export default Registration;