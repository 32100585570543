import "../../../Styles/faq.css"
import FAQRow from "./FAQRow";

export interface FAQ_q {
    title: string,
    desc: any
}

interface Props {
    questions: FAQ_q[]
}

function FAQ({questions}: Props) {

    return ( 
    <div className="faq centerAlign">

        <div>
        {questions.map((q, i: number) => <FAQRow data={q} key={i}/>)}
        </div>

    </div>
     );
}

export default FAQ;