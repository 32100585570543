import { PassResetData, RegistrationData, StrIndexedObj } from "./misc";

//const defaultUrl = "http://localhost:8080"
const defaultUrl = "https://omega-post-416123.ey.r.appspot.com"
const maxAttempt = 5;

// Basic req functions
const sendReq = (opts: Object, url: string, endpoint: string, errMsgSetter?: Function, attemptN = 0): any => {
  return fetch(url + endpoint, opts)
    .then(async (res) => {
      let temp = await res.json();
      temp.status = res.status;

      if (temp.repeat && attemptN < maxAttempt) {
        console.log(`Server not ready, retrying (attempt ${attemptN})`)
        await new Promise((resolve) => setTimeout(resolve, 750));
        return sendReq(opts, url, endpoint, errMsgSetter, attemptN + 1)
      }

      console.log("Request completed on attempt " + attemptN)
      return temp;
    })
    .catch((err) => {
      console.log(err);
      if (errMsgSetter) {errMsgSetter("Něco se pokazilo")}
    });
};

export const sendGetReq = async (endpoint: string, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "GET",
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};

export const sendPostReq = async (endpoint: string, body: StrIndexedObj, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    } 
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};




// Abstract req functions
// REGISTRATION
export const getSchools = async (setter: Function) => {

  let res = await sendGetReq("/auth/getSchools");

  if (res.data) {
    setter([ ...res.data, "Jiné"]) // This seems like the better solution for now
  }

  return
}

export const registerReq = async (data: RegistrationData, errMsgSetter?: Function) => {

  if (data.id) {delete data.id}
  data.schoolCustom = data.school !== "Jiné" ? "" : data.schoolCustom // whaaattheeffff :(

  let res = await sendPostReq("/auth/register", data)

  if (res) {
    if (res.status !== 200) {
      if (errMsgSetter) {errMsgSetter(res.msg)}
      return
    }

    data.id = res.id

    if (errMsgSetter) {errMsgSetter("Registrace proběhla úspěšně! Nahrávání profilového obrázku...")}

    uploadPfp(data)
  }
  return
}

const uploadPfp = async (teamData: RegistrationData) => {
 
    const fileInput = document.getElementById("teamPfpSelector") as HTMLInputElement;
    if (!fileInput.files) {window.location.replace("/thankYou");return} // thank you typescript very cool

    const file = fileInput?.files[0];

    if (!file) {
      window.location.replace("/thankYou");
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // max file size of 5mb
      alert("Nahraný obrázek je příliš velký, maximální povolená velikost je 5MB")
      return
    }

    const formData = new FormData();
    formData.append("pfp", file);
    formData.append("id", teamData.id ? teamData.id : "0") // loool
    formData.append("teamName", teamData.teamName)

    try {
      const response = await fetch(defaultUrl+"/upload/uploadPfp", {
        method: "POST",
        body: formData
      });

      if (response.ok) {
        /*const result = await response.json();*/
        window.location.replace("/thankYou")
      } else {
        const error = await response.json();
        console.log(error.error)
        alert('Obrázek se bohužel nepodařilo nahrát, napište nám prosím na info@smoulasoutez.cz')
        window.location.replace("/thankYou")
      }
    } catch (error: any) {
      console.log('Error uploading image: ' + error.message)
      alert('Obrázek se bohužel nepodařilo nahrát, váš tým ale zaregistrován je!')
    }
}

export const getConfirmationData = async (token: string, setter: Function) => {

  let res = await sendPostReq("/auth/getConfirmationData", {token: token})

  if (res.data) {
    setter(res.data)
  }
  return
}

export const confirmMember = async (token: string, setter: Function) => {

  let res = await sendPostReq("/auth/confirmMemberReg", {token: token})

  if (res.confirmed) {
    setter((prev: any) => {
      const updated = { ...prev };
      updated.gdpr = 1
      return updated;
    })
  } // what
  return

}

export const checkPassResetToken = async (token: string, setter: Function, errMsgSetter?: Function) => {

  let res = await sendPostReq("/auth/checkPassResetToken", {token: token})

  if (res) {
    if (res.status !== 200) {
      if (errMsgSetter) {errMsgSetter(res.msg)}
      setter(false)
      return
    }

    setter(res.result)
  }
  return  

}

export const passResetReq = async (data: PassResetData, errMsgSetter: Function) => {

  let res = await sendPostReq("/auth/passReset", data)

  if (res) {
    if (res.status !== 200) {
      if (errMsgSetter) {errMsgSetter(res.msg)}
      return
    }

    errMsgSetter("Heslo úspěšně změněno")
  }
  return  

}