import { Link } from "react-router-dom";

function Contact() {
    return ( 
        <div className="contact centerAlign column">
            <h1>Kontakt</h1>
            <h2>
                V případě potřeby nás prosím kontaktujte na <a href="mailto:info@smoulasoutez.cz">info@smoulasoutez.cz</a> :)
                <br/><small>Tel: <a href="tel:+420228222698">+420 228 222 698</a></small>
            </h2>

            <div className="footer">
                <h3>
                    Šmoula a další související známky a loga jsou ochrannými známkami společnosti <a href="https://www.smurfbusiness.com/">IMPS SA</a> a byly využity se svolením.
                    <br/>&copy; ŠMouLa {new Date().getFullYear()}
                </h3>
                <h5>
                    <br/>
                    <Link to={"/zasadyOchranyOsobnichUdaju"}>Zásady ochrany osobních údajů</Link>
                </h5>
            </div>
        </div> 
     );
}

export default Contact;