import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Homepage from './Pages/Homepage';

import { Link } from 'react-router-dom';

import './Styles/main.css';
//import RegistrationPage from './Pages/Registration/RegistrationPagePlaceholder';
import RegistrationPage from './Pages/Registration/RegistrationPage';

import "./Styles/specialClasses.css"
import { useSpecialClassHandler } from './Scripts/hooks/useSpecialClassHandler';
import PageNotFound from './Pages/PageNotFound';

import Skibidi from './Pages/Skibidi'

import CookieConsent from "react-cookie-consent";
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import TeamDetailsPage from './Pages/TeamDetailsPage';
import GameDetailsPage from './Pages/GameDetailsPage';
import { useEffect } from 'react';
import ThankYouPage from './Pages/Registration/ThankYouPage';
import RegistrationConfirmationPage from './Pages/Registration/RegistrationConfirmationPage';
import PassResetPage from './Pages/Registration/PassResetPage';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDyGGzg6uxQKnYp-Bq6N3UVPP7kd7gHM8A",
  authDomain: "smoulahomepage.firebaseapp.com",
  projectId: "smoulahomepage",
  storageBucket: "smoulahomepage.firebasestorage.app",
  messagingSenderId: "898459952982",
  appId: "1:898459952982:web:e9da74b565749ac6e2d8f7",
  measurementId: "G-DE28KEH13X"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  useSpecialClassHandler() //TODO: Do I need to call this on every page?

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, []) // yupp

  return (
    <BrowserRouter>
      <CookieConsent
        location="top"
        buttonText="Souhlasím"
        cookieName="acceptedCookie"
        style={{ background: "#4695d6", zIndex: "9999999" }}
        buttonStyle={{ background: "#ffffff", color: "#4695d6", fontSize: "13px" }}
        expires={150}
      >
        Tato stránka používá c🍪🍪kies.{" "}
        <span style={{ fontSize: "10px" }}>Používáme je s cílem zajistit co možná nejlepší zážitek při návštěvě našich webových stránek a za účelem analýzy návštěvnosti. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro analýzu. Partneři tyto údaje mohou zkombinovat s dalšími informacemi, které jste jim poskytli nebo které získali v důsledku toho, že používáte jejich služby. <Link to={"/zasadyOchranyOsobnichUdaju"} style={{ color: "#ddd" }}>Více informací zde.</Link></span>
      </CookieConsent>

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/tym" element={<TeamDetailsPage />} />
        <Route path="/hra" element={<GameDetailsPage />} />

        <Route path="/registrace" element={<RegistrationPage />} />
        <Route path="/thankYou" element={<ThankYouPage />} />
        <Route path="/potvrzeniRegistrace" element={<RegistrationConfirmationPage />} />
        <Route path="/zmenaHesla" element={<PassResetPage />} />

        <Route path="/zasadyOchranyOsobnichUdaju" element={<PrivacyPolicyPage />} />

        <Route path="/skibidi" element={<Skibidi />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
