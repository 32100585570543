import FAQ from "../General/FAQ/FAQ";

function FAQPage() {

    const questions = [
        {
            title: "Kde se soutěž koná?",
            desc: "Soutěž probíhá online, nejčastěji na školách účastníků. Lze ji ale řešit kdekoli, kde máte internetové připojení!"
        },
        {
            title: "Kdy se soutěž koná?",
            desc: "Soutěž se bude konat 12. května 2025 od 9:00 do 12:00."
        },
        {
            title: "Kolik času soutěž zabere?",
            desc: "Soutěž samotná trvá od 9:00 do 12:00 dopoledne, přičemž následné vyhodnocení trvá přibližně 15 minut. Zároveň si doporučujeme vyhradit cca. 10 minut na přihlášení do soutěžního prostředí a zajištění fungujícího technického zázemí před začátkem soutěže."
        },
        {
            title: "Může být v jednom týmu více žáků z jiných ročníků?",
            desc: "V jednom týmu mohou být i žáci napříč ročníky, každý člen však musí navštěvovat 6. až 9. třídu ZŠ (nebo odpovídající ročníky víceletých gymnázií). Zároveň je silně doporučeno, aby se členové týmů předem znali."
        },
        {
            title: "Vyplňují týmy řešení na jednom počítači, nebo je výhodnější, aby měl každý z týmu svůj počítač?",
            desc: "Z technických důvodů doporučujeme, aby týmy řešení vyplňovaly na jednom počítači. Pokud ale bude mít každý z členů týmu svůj počítač, výrazně mu to ulehčí práci při práci na jednotlivých úlohách, jelikož si bude moci lépe úlohy rozdělit."
        },
        {
            title: "Pokud bychom ze školy přihlásili více týmů, mají všechny týmy stejné otázky a ve stejném pořadí?",
            desc: "Ano, týmy budou mít stejné jak otázky, tak jejich pořadí."
        },
        {
            title: "Kolik týmů se může maximálně zaregistrovat z jedné školy?",
            desc: "Počet týmů z jedné školy nijak nelimitujeme."
        },
        {
            title: "Může být některý ze členů v jiných ročnících, než je 2. stupeň ZŠ?",
            desc: "V tuto chvíli toto není možné."
        },
        {
            title: "Kdy máte v plánu otevřít registrace na ročník soutěže 2025?",
            desc: "Registrace již byly otevřeny!"
        },
        {
            title: "Mohu se podívat na úlohy z předchozích ročníků soutěže?",
            desc: "Úlohy naleznete na <a href='https://archiv.smoulasoutez.cz/' target='_blank' rel='noopener noreferrer'>archiv.smoulasoutez.cz</a>."
        },
        {
            title: "Do kdy se můžeme zaregistrovat?",
            desc: "Týmy samotné se mohou zaregistrovat do 4. 5. 2025, 23:59. Učitelé mohou týmy ze své školy registrovat až do 9. 5. 2025 23:59 pomocí <a href='https://ucitel.smoulasoutez.cz'>učitelského portálu</a>."
        },
        {
            title: "Kdo soutěž organizuje?",
            desc: "Soutěž je plně organizována studenty gymnázia <a href='https://porg.cz/novy-porg-praha' target='_blank' rel='noopener noreferrer'>Nový PORG</a> (píšou veškeré úlohy; zajišťují technické zázemí, sponzory a další) s jeho záštitou. Veškeré úlohy prochází kontrolou ostatních studentů a následně i učitelů, aby bylo zajištěna jejich kvalita."
        },
        {
            title: "Kolik kol soutěž má?",
            desc: "Soutěž má pouze jedno kolo, které probíhá on-line. Po jeho skončení se účastníci ihned dozví, jak se umístili."
        },
        {
            title: "Mám další otázku!",
            desc: "Kontaktujte nás na <a href='mailto:info@smoulasoutez.cz'>info@smoulasoutez.cz</a> nebo na telefonním čísle <a href='tel:+420228222698'>+420 228 222 698</a>."
        }
    ]

    return ( 
    <div className="faqPage column centerAlign">

        <h1>Často kladené dotazy</h1>

        <FAQ questions={questions}/>

    </div>
     );
}

export default FAQPage;