import { Link } from "react-router-dom";
import Icon from "../General/Icon";

function RegBtn() {
    return (
        <Link to={"/registrace"}>
            <div className="regBtn centerAlign" style={{ filter: "invert(1)", mixBlendMode: "difference" }}>
                <Icon iconName={"person-add-sharp"} size="max(2vw,2vh)" color="black" />
                <h5 style={{ marginLeft: "0.5vw", fontSize: "max(1vw,1.2vh)", color: "black" }}>Registrace</h5>
            </div>
        </Link>
    );
}

export default RegBtn;